@import 'app/Components/UI/variables.scss';

/* styleForPDF */
.ContentArticle,
.ContentArticleChild {
  /** estilos generales listas (en teoría debiera hacerse con ::marker pero safari no soporta los counters para lista alfanumérica y numérica, por tanto, se realizó con ::before, al usarse ajustamos manualmente los márgenes al pixel que por naturaleza ::marker tiene) */
  > dl,
  > ol,
  > ul {
    margin-bottom: 32px;
    > li {
      font-family: $fontRoboto;
      font-size: 18px;
      line-height: 31px;
      margin-bottom: 16px;
      padding-left: 15px;

      > ul,
      ol {
        margin-top: 16px;
        padding-left: 32px;
        > li {
          margin-bottom: 16px;
          > ul {
            margin-top: 16px;
            > li {
              margin-bottom: 16px;
            }
          }
        }
      }

      &::before {
        font-weight: bold;
      }
      strong {
        font-family: $fontRoboto;
        font-size: 16px;
        font-weight: bolder;
        line-height: 31px;

        @media screen and (min-width: 768px) {
          font-size: 18px;
        }
      }
    }
  }

  // estilos alpha y numeric
  > ol {
    &.list-number {
      counter-reset: list-item, 1;
      margin-left: 15px;
      padding: 0px 20px;
      li {
        list-style-type: none;
        &::before {
          content: counters(list-item, '.') '.';
          margin-left: -32px;
          padding-right: 15px;
        }
      }
      ol {
        margin-top: 16px;
        margin-left: 0;
        padding-right: 0;
        > li {
          padding-left: 15px;
        }
        li {
          font-family: $fontRoboto;
          list-style-type: none !important;
          &::before {
            font-weight: bold;
            margin-left: -48px;
          }
          > ol {
            li {
              padding-left: 25px;
              &::before {
                font-weight: bold;
                margin-left: -63px;
              }
            }
          }
        }
      }
    }
    &.list-alpha {
      counter-reset: lista;
      margin-left: 15px;
      list-style-type: none;
      padding: 0px 20px;

      li {
        counter-increment: lista;
        &::before {
          font-weight: bold;
          content: counter(lista, upper-latin) '.';
          margin-left: -32px;
          padding-right: 15px;
        }
      }
      ol {
        counter-reset: number;
        margin-top: 16px;
        margin-left: 0;
        padding-right: 0;
        > li {
          list-style-type: none;
          padding-left: 15px;
        }
        li {
          counter-increment: number;
          &::before {
            content: counter(lista, upper-latin) '.' counters(number, '.') '. ';
            margin-left: -52px;
          }
          > ol {
            li {
              padding-left: 25px;
              &::before {
                margin-left: -67px;
              }
            }
          }
        }
      }
    }
  }

  // lista desordenada no trae clase por back
  > ul {
    padding-left: -20px;
    > li {
      padding-left: 10px;
      font-family: $fontRoboto;
      font-size: 18px;
      line-height: 31px;
      margin-bottom: 16px;
      padding-top: 2px;

      > ul {
        margin-top: 16px;
        padding-left: 20px;

        ul > li {
          list-style: none;
          &::before {
            content: '-';
            margin-left: -35px;
            padding-right: 20px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    ul,
    ol {
      padding-left: 20px;
      > li {
        ul {
          li {
            padding-left: 15px;
          }
        }
      }
    }

    // estilos generales listas
    dl,
    ol,
    ul {
      > li {
        font-size: 16px;
      }
    }

    ol {
      &.list-number {
        margin-left: 20px;
        padding: 0;
        ol {
          > li {
            padding-left: 40px;
          }
          li {
            &::before {
              margin-left: -45px;
            }
            > ol {
              li {
                padding-left: 60px;
                &::before {
                  margin-left: -60px;
                }
              }
            }
          }
        }
      }
      &.list-alpha {
        margin-left: 20px;
        padding: 0;
        ol {
          > li {
            padding-left: 50px;
          }
          li {
            &::before {
              margin-left: -48px;
            }
            > ol {
              li {
                padding-left: 60px;
                &::before {
                  margin-left: -60px;
                }
              }
            }
          }
        }
      }
    }
  }
}
