@import 'app/Components/UI/variables.scss';

/* styleForPDF */
.QuestionScale{
  margin-top: 20px;

  .form-check {
    padding-left: 0px;
    width: 100%;
    margin-bottom: 25px;
  }

  .Initial__Final__Scale {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    font-size: 16px;

    .Initial__Scale, .Final__Scale {
      max-width: 150px;
      line-height: 23px;
    }

    .Final__Scale {
      text-align: right;
    }
  }

  .Options__List {
    list-style: none;
    display: flex;
    justify-content: space-between;
    padding-inline-start: 0px;

    .Question__Option {
      .form-check-input {
        position: inherit;
        margin-top: 0px;
        margin-left: 0px;
      }

      .Option__Tooltip__Input {
        width: 30px;
        height: 40px;
      }

      .form-check-label {
        text-align: center;
        cursor: pointer;
        .emoji {
          position: relative;
          top: -10px;
        }
      }

      .Option__Label {
        font-size: 14px;
        margin-top: 10px;
        position: relative;
        top: -10px;
      }
    }

    .Option__Number {
      display: none;
    }
  }
}
