@import './functions.scss';

// colors
$primary: #0189ff;
$info: #e0eefa;
$default: #c9c9d3;
$success: #e9f6ec;
$warning: #fdeed6;
$danger: #f9dfdf;
$active: #adadbd;
$text: #9a9aa4;
$icons: #b0cfe0;
$completed: #60798e;
$buttonSuccess: #2cb26b;
$black: #2f2f2f;
$brownishGrey: #5c5c5c;
$blackTwo: #333;
$blackThree: #2f2f2f;
$blackFour: #444;
$brownGrey: #797979;
$brownThree: #808080;
$veryLightPink: #c6c6c6;
$brownGreyTwo: #999;
$veryLightPinkTwo: #e8e8e8;
$veryLightPinkThree: #f9dfdf;
$deepSeaBlue: #005580;
$deepSkyBlue: #0189ff;
$backgroundGrey: #e6e6e6;
$white: #f4f4f4;
$whiteTwo: #f8f8f8;
$whiteThree: #fff;
$whiteFour: #e8e8e8;
$whiteFive: #d8d8d8;
$whiteSix: #f5f5f5;
$whiteDisabled: #f1f1f1;
$red: #c00;
$jadeGreen: #28a745;
$orangeyYellow: #f5a623;
$orange: #ef6c00;
$veryLightBlue: #e0eefa;
$ice: #e9f6ec;
$activeBlue: #2488f7;
$inactiveBlue: #b0cfe0;
$checkGreen: #28a745;
$checkError: #c00;
$lightMint: #c3e7cb;
$paleSalmon: #f0b5b5;
$assitant: #cde8ff;
$hoverBlock: #bee1ff;
$dodgerBlue: #2196f3;
$backgroundGreyTwo: #ececec;

// Fuente
$fontLora: 'Lora', serif;
$fontRoboto: 'Roboto', serif;

// Borde
$borderRadius: 10px;
$borderSolid: 1px solid #e6e6e6;
$borderEdit: 1px solid $primary;
