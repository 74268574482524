@import 'app/Components/UI/variables.scss';

/* styleForPDF */
.AlternativeContentPDF {
  background-color: $whiteSix;
  padding: scaleDPI(10);
  margin-bottom: 32px;

  hr.pdfpagebreak {
    border: 0;
    margin: 0;
    page-break-after: always;
  }

  img {
    min-width: 10px;
    max-width: 100%;
  }

  &__image {
    margin-bottom: scaleDPI(10);

    img {
      width: 100%;
    }
  }

  &__content {
    font-size: scaleDPI(8);
    margin-bottom: scaleDPI(10);

    p {
      clear: both;
    }

    img {
      float: left;
      margin-left: 50%;
      transform: translateX(-50%);

      &.fr-fir,
      &.fr-fil {
        margin-left: 0;
        transform: translateX(0);
      }

      &.fr-fir {
        float: right;
      }
    }
  }

  &__footer {
    align-items: center;
    display: flex;
    font-size: scaleDPI(8);
    justify-content: space-between;

    p {
      margin: 0;
    }

    > .qr {
      padding-left: scaleDPI(20);

      > img {
        max-width: scaleDPI(45);
      }

      canvas {
        display: none;
      }
    }

    > .text {
      align-items: flex-start;
      display: flex;

      p {
        margin-top: -3px;
        text-align: left !important;
      }

      > img {
        margin-right: scaleDPI(5);
        width: initial;
      }
    }

    a {
      color: $primary;
      font-weight: 500;
      margin: 0 scaleDPI(3);
      text-decoration: underline;

      > svg {
        margin-left: scaleDPI(5);
        margin-right: 0;
      }
    }
  }

  .DownloadFiles {
    margin: 0 0 scaleDPI(10);

    .File {
      font-size: scaleDPI(10);

      a {
        display: none;
      }
    }
  }
}
