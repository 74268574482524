@import 'app/Components/UI/variables.scss';

/* styleForPDF */
.Article__image_break {
  margin-bottom: 32px;

  img{
    image-rendering: -webkit-optimize-contrast;
    width: 100%;
  }
  p {
    font-family: $fontRoboto;
    font-size: 12px;
    font-style: italic;
    line-height: 16px;
    margin-top: 10px;
    padding-left: 8px;
  }

  &.float {
    max-width: max-content;
    width: 250px;

    &.left {
      float: left;
      margin-left: -102px;
      margin-right: 40px;
    }
    &.right {
      float: right;
      margin-left: 40px;
      margin-right: -102px;
    }
  }

  &.hasFixedSize{
    margin: 0;
  }

  @media screen and (max-width: 990px) {
    &.float {
      margin-bottom: var(--article-margin);

      &.left,
      &.right {
        float: none;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

.WrapperViewPDF {
  .Article__image_break {
    // caja contextual
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    p {
      font-size: scaleDPI(8);
      line-height: normal;
    }

    &.float {
      float: none;
      margin-left: auto !important;
      margin-right: auto !important;
      margin-bottom: var(--article-margin);
      max-width: 100%;

      img {
        max-width: scaleDPI(124); // = 250px
        image-rendering: -webkit-optimize-contrast;
      }
    }

    img {
      max-width: max-content;
      width: 100%;
    }
  }
}
