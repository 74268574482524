@import 'app/Components/UI/variables.scss';

/* styleForPDF */
.ComponentDownloadFiles {
  padding: 0;
  list-style: none;
  margin-top: 16px;
  margin-bottom: 32px;

  li {
    border: 1px solid $veryLightPink;
    border-radius: $borderRadius;
    background: $whiteTwo;
    color: $blackTwo;
    margin: 0 0 16px 0;
    padding: 10px 20px;

    .text_button {
      color: $white;
      font-size: 14px;
      font-weight: bold;
      text-transform: capitalize;
    }
    span {
      color: $brownGreyTwo;
      font-size: 12px;
      margin-left: 5px;
      text-transform: uppercase;
    }
  }
}

.ComponentDownloadFile {
  padding: 0;
  list-style: none;
  margin-bottom: 32px;

  .Svg__Icon {
    margin-right: 0px;
  }

  .File {
    border: 1px solid $veryLightPink;
    border-radius: $borderRadius;
    background: $whiteTwo;
    color: $blackTwo;
    margin-bottom: 10px;
    padding: 10px 20px;

    &:last-child {
      margin: 0;
    }
    .text_button {
      color: $white;
      font-size: 14px;
      font-weight: bold;
      text-transform: capitalize;
      margin-right: 8px;
    }

    .button__container {
      font-size: 14px;
      padding: 5px 10px;
    }
  }
}
