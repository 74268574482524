@import 'app/Components/UI/variables.scss';

/* styleForPDF */
.Article__accordeon {
  .panel {
    margin-bottom: 10px;

    .card-header {
      background-color: rgba($deepSkyBlue, 0.1);
      border-bottom: 1px solid $whiteFour;
      border-left: 3px solid $deepSkyBlue;
      border-right: 1px solid $whiteFour;
      border-top: 1px solid $whiteFour;
      padding: 0px;
      position: relative;

      &.collapsed {
        background: white;

        &:before {
          content: url('../../../../../assets/svg/accordionClosed.svg');
          color: $brownGreyTwo;
        }
      }

      &:before {
        content: url('../../../../../assets/svg/accordionOpen.svg');
        color: $primary;
        cursor: pointer;
        line-height: 9px;
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
      }

      .Accordion__Title {
        color: $blackTwo;
        cursor: pointer;
        font-family: $fontRoboto;
        font-size: 18px;
        line-height: 31px;
        max-width: 95%;
        padding: 20px;
        text-decoration: none;
      }

      h5 {
        color: $blackTwo;
        cursor: pointer;
        font-family: $fontRoboto;
        font-size: 18px;
        line-height: 31px;
        margin: 0;
        overflow: hidden;
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 20px;
      }
    }

    .card-body {
      border-bottom: 1px solid $whiteFour;
      border-left: 3px solid $deepSkyBlue;
      border-right: 1px solid $whiteFour;
      padding: 30px 20px;

      p {
        font-size: 18px;
        margin: 0;
      }
    }
    &:nth-child(3n-1) {
      .card-header,
      .card-body {
        border-left: 3px solid rgba($deepSkyBlue, 0.6);
      }
    }

    &:nth-child(3n) {
      .card-header,
      .card-body {
        border-left: 3px solid rgba($deepSkyBlue, 0.3);
      }
    }
  }

  @media screen and (max-width: 768px) {
    .Accordion__Title {
      font-size: 16px;
    }
    
    .card-body {
      p {
        font-size: 16px;
        line-height: 28px;
      }
    }
  }
}

.WrapperViewPDF{
  .Article__accordeon {
    .panel-group {
      .panel{
        border-left: scaleDPI(3) solid $primary;

        .card-header {
          background-color: $info;
          border-bottom: none;
          border-left-width: 0;

          &:before {
            display: none;
          }

          .Accordion__Title {
            font-family: $fontRoboto;
            font-size: scaleDPI(10);
            line-height: scaleDPI(13);
            padding: scaleDPI(10) scaleDPI(8.5);
          }
        }

        .collapse:not(.show){
          display: block;
        }
      }

      .collapse {
        .card-body {
          padding: scaleDPI(10);
          border-left-width: 0;

          p {
            font-family: $fontRoboto;
            font-size: scaleDPI(10);
            line-height: scaleDPI(13);
          }
        }
      }
    }
  }
}
