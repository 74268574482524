@import 'app/Components/UI/variables.scss';

/* styleForPDF */
.FeedbackQuestion{
  background-color: $whiteTwo;
  border-radius: 10px;
  font-size: 14px;
  margin-bottom: 30px;
  padding: 24px;
  .State {
    align-items: center;
    display: flex;
  }
  .Text {
    padding-top: 7px;

    p {
      font-size: 14px;
      margin-bottom: 0;
    }

    .CorrectAnswer {
      font-weight: bold;
    }

    .Article__image_zoom {
      margin: 0;
      width: 100%;
    }
  }
  hr {
    border: solid 1px;
    border-color: #dededf !important;
    margin: 0;
    margin-top: 19px;
    margin-bottom: 19px;
  }
}
