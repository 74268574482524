@import 'app/Components/UI/variables.scss';

:root {
  --article-margin: 2.5rem;
}

/* styleForPDF */
.ContentArticle,
.ContentArticleChild {
  color: $blackTwo;
  font-family: $fontRoboto;
  padding: 0 16px;

  @media screen and (min-width: 1024px) {
    padding: 0;
  }

  > *:last-child {
    margin-bottom: var(--article-margin);
  }

  hr.pdfpagebreak {
    border: 0;
    margin: 0;
    page-break-after: always;
  }

  > section,
  > .test-embed {
    clear: both;
    margin-bottom: 32px;
  }

  > iframe {
    max-width: 100%;
    margin-bottom: 32px;
  }

  > video {
    width: 100% !important;
    max-width: 100%;
  }

  > img,
  > figure {
    margin-bottom: 32px;
  }

  .stylesInside {
    .breakerContainer {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
}

.ContentArticleChild {
  > *:last-child {
    margin-bottom: 0;
  }
}

.WrapperViewPDF .ContentArticle,
.WrapperViewPDF .ContentArticleChild {
  max-width: 100%;

  > hr {
    margin-bottom: scaleDPI(20);
    margin-top: scaleDPI(20);
  }

  hr.pdfpagebreak {
    & + p,
    & + section[class*='Article__'].float + p {
      margin-top: 0;
    }
  }

  > .Article__outstanding {
    margin-bottom: 32px;
  }

  // si el contenido se corta lo pasamos a la otra línea
  > ul li,
  > ol li,
  > .Article__quote, // cita
  > .Article__outstanding, // nota
  > .SlideshowPDF .slidePDF,
  > .Footer__Licence__brand, // licencia y fecha
  > .Article__definitions li, // definiciones
  > .Article__references li // referencias
  {
    page-break-inside: avoid;
  }
}
