@import 'app/Components/UI/variables.scss';

/* styleForPDF */
.Article {
  &__outstanding {
    &:before {
      content: url('../../../../../assets/svg/asterisco.svg');
      display: block;
      line-height: 0;
      margin-bottom: 20px;
      text-align: center;
    }

    p {
      color: $completed;
      font-family: $fontLora;
      font-size: 20px;
      line-height: 34px;
      text-align: center;
    }
  }

  &__featured {
    width: 318px;
    
    h4 {
      font-family: $fontLora;
      font-size: 35px;
      font-weight: bold;
      line-height: normal;
      margin: 0;
      
      & + p {
        margin-top: 10px;
      }
    }
    
    > div {
      padding: 32px 48px;
      position: relative;
    }

    p {
      font-family: $fontLora;
      font-size: 20px;
      line-height: 34px;
      position: relative;
      text-rendering: auto;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.block {
      width: 100%;
    }

    &.float {
      > div {
        padding: 16px;
      }
      &.left {
        float: left;
        margin-left: -102px;
        margin-right: 40px;
      }
      &.right {
        float: right;
        margin-right: -102px;
        margin-left: 40px;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    &__featured {
      &.float {
        &.left {
          margin-left: -98px;
        }
        &.right {
          margin-right: -98px;
        }
      }
    }
  }

  @media screen and (max-width: 990px) {
    &__featured {
      &.float {
        width: 50%;
        
        &.left{
          margin-left: 0;
        }
        &.right{
          margin-right: 0;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    &__outstanding {
      p {
        font-family: $fontLora;
        font-size: 18px;
        line-height: 30px;
      }
    }

    &__featured {
      background-color: orange;
      p {
        font-family: $fontLora;
        font-size: 18px;
        line-height: 30px;
      }
    }
  }

  @media screen and (max-width: 764px) {
    &__featured {
      width: 100%;
  
      >div {
        padding: 32px 16px;
        position: relative;
      }
  
      >h4 {
        font-size: 25px;
      }
  
      &.float {
        &.left,
        &.right {
          float: none;
          margin-left: 0;
          text-align: left;
          width: 100%;
        }
        >div {
          padding: 32px 16px;
        }
      }
    }
  }
}

.WrapperViewPDF {
  .Article {
    &__outstanding {
      &:before {
        font-size: scaleDPI(10);
      }

      p {
        font-size: scaleDPI(11);
        line-height: normal;
        padding: 0px scaleDPI(10);
      }
    }

    &__featured {
      padding: 0;

      > h4 {
        font-size: scaleDPI(17);

        & + p {
          margin-top: scaleDPI(6);
        }
      }

      p {
        font-size: scaleDPI(11);
        line-height: normal;
      }

      &.block {
        width: 100%;
      }

      &.float {
        max-width: scaleDPI(180);

        + p {
          max-width: 100%;
        }

        &.left {
          float: left;
          margin-left: 0 !important;
          margin-right: scaleDPI(20) !important;
        }

        &.right {
          float: right;
          margin-left: scaleDPI(20) !important;
          margin-right: 0 !important;
        }
      }
    }
  }
}
