.BreadCrumb.BreadCrumbFixed{
  width: 100%;
  top: 0;
  position: fixed;
  z-index: 1000;
  nav{
    animation: inBreadCrumb .3s forwards;
    opacity: 0;
  }
}

@keyframes inBreadCrumb {
  from { opacity: 0 }
  to { opacity: 1 }
}
