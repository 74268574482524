@import 'app/Components/UI/variables.scss';

/* styleForPDF */
.ContentArticle,
.ContentArticleChild {
  > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > h6,
  > p,
  > a,
  > li,
  > strong {
    word-break: break-word;
  }

  > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > h6 {
    margin: 0 0 1rem;
  }

  > h3 {
    font-family: $fontRoboto;
    font-size: 36px;
    font-weight: bold;
    line-height: 50px;
  }

  > h4 {
    font-family: $fontRoboto;
    font-size: 32px;
    font-weight: medium;
    line-height: 52px;
  }

  > h5 {
    font-family: $fontRoboto;
    font-size: 26px;
    font-weight: medium;
    line-height: 43px;
  }

  > h6 {
    color: $black;
    font-family: $fontRoboto;
    font-size: 20px;
    font-weight: bold;
    line-height: 32px;
  }

  > p,
  > div.question {
    color: $black;
    font-family: $fontRoboto;
    font-size: 18px;
    margin-bottom: 32px;
    line-height: 31px;
  }

  @media screen and (max-width: 768px) {
    > h2 {
      font-size: 28px;
    }

    > h3,
    > h4,
    > h5,
    > h6 {
      font-family: $fontRoboto;
    }

    > h3 {
      font-size: 28px;
      line-height: 43px;
    }

    > h4 {
      font-size: 26px;
      line-height: 40px;
    }

    > h5 {
      font-size: 23px;
      line-height: 36px;
    }

    > h6 {
      font-size: 20px;
      line-height: 32px;
    }

    > p {
      font-family: $fontRoboto;
      font-size: 16px;
      line-height: 28px;
    }
  }
}

.WrapperViewPDF .ContentArticle,
.WrapperViewPDF .ContentArticleChild {
  > p {
    font-family: $fontRoboto;
    font-size: scaleDPI(10);
    letter-spacing: normal;
    line-height: scaleDPI(13);
    text-align: justify;

    .footnote {
      font-size: scaleDPI(8);
      top: 0;
    }

    span[data-toggle='tooltip'] {
      :before {
        transform: scale(0.8);
      }
    }
  }

  > h3,
  > h4,
  > h5,
  > h6 {
    font-family: $fontRoboto;
    font-weight: bold;
  }

  > h3 {
    font-size: scaleDPI(17);
    line-height: scaleDPI(23);
  }

  > h4 {
    font-size: scaleDPI(15);
    line-height: scaleDPI(20);
  }

  > h5 {
    font-size: scaleDPI(13);
    line-height: scaleDPI(18);
  }

  > h6 {
    font-size: scaleDPI(11);
    line-height: scaleDPI(15);
  }
}
