@import 'app/Components/UI/variables.scss';

/* styleForPDF */
.Parallax {
  margin-bottom: 5rem;
  margin-top: 5rem;
  overflow: hidden;
  position: relative;

  .react-parallax-content {
    padding: 20px;
  }

  .Content {
    display: grid;
    row-gap: 0.625rem;
    grid-column: 1fr;
    max-width: 500px;
    min-width: 250px;
    padding: 20px;

    &.white {
      &:not(.none) {
        background-color: rgba(0, 0, 0, 0.65);
      }
      p,
      h3 {
        color: white;
      }
    }

    &.black {
      &:not(.none) {
        background-color: rgba(255, 255, 255, 0.65);
      }
      p,
      h3 {
        color: black;
      }
    }

    h3 {
      font-size: 28px;
      font-weight: 700;
      line-height: 43px;
      margin: 0;
      text-align: center;
    }

    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
      margin: 0;
      text-align: center;
    }
  }

  @media screen and (min-width: 720px) {
    .Content {
      h3 {
        font-size: 36px;
        line-height: 52px;
      }

      p {
        font-size: 18px;
        line-height: 31px;
      }
    }
  }
}

.WrapperViewPDF {
  .Parallax {
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    height: 330px;
    justify-content: center;

    .Content {
      min-width: 400px;
    }
  }
}
