@import 'app/Components/UI/variables.scss';

/* styleForPDF */
.testEmbed {
  .box {
    background: none;
    border-bottom: 1.5px solid $veryLightPinkTwo;
    border-left: 1.5px solid $veryLightPinkTwo;
    border-right: 1.5px solid $veryLightPinkTwo;
    padding: 42px 52px 48px;

    .FeedbackQuestion {
      margin-bottom: 0;
      margin-top: 20px;
    }

    li.QuestionAlternatives {
      border: 0;
    }

    .alternatives {
      & > div:not(.FeedbackQuestion) {
        margin-top: 0;
      }
    }
  }

  .Article__image_box {
    margin: 0;
  }

  .float {
    float: none !important;
  }

  .Article__image_break {
    margin-bottom: 10px !important;
  }

  .Text {
    margin-top: 0px !important;

    p {
      line-height: 25px !important;
    }
  }

  .State {
    align-items: center !important;
  }

  .Question {
    border-bottom: none;

    .Question__Data {
      margin-top: 0px;

      .Question__Content {
        .rdw-editor-wrapper {
          margin-bottom: 0px;
        }

        .form-group {
          margin-bottom: 0px !important;
          margin-top: 20px !important;
        }
      }
    }
  }

  .Question__Info {
    display: none !important;
  }

  .Options {
    .form-group,
    .form-check {
      margin-bottom: 0px;
    }

    .Options__List {
      margin-bottom: 0;
      margin: 0px;

      li {
        margin-bottom: 0px !important;
      }

      li::before {
        background-color: none !important;
        content: none;
      }
    }

    .grid {
      li:last-child {
        margin-bottom: 0px;
      }

      span {
        margin-right: 9px;
      }
    }
  }

  button {
    font-weight: 500;
    margin-top: 32px;
  }

  h5 {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: -16px !important;
    span {
      margin-right: 16px;
      max-width: 300px;
    }

    &::before,
    &::after {
      content: '';
      border-bottom: 1px solid $veryLightPinkTwo;
      flex: 1 1;
    }

    img {
      margin-left: 16px;
      margin-right: 16px;
    }
  }

  @media screen and (max-width: 770px) {
    .Options {
      li {
        padding-left: 0px !important;
      }
    }
  }

  @media screen and (max-width: 640px) {
    h5 {
      font-size: 18px !important;
    }

    .box {
      padding: 45px 20px 20px 20px;
    }

    &-title {
      h5 {
        font-size: 18px;
      }
    }

    .Options {
      li {
        border-bottom: none;
      }
    }

    .Text {
      p {
        line-height: 22px !important;
      }
    }
  }

  @media screen and (max-width: 640px) {
    button {
      width: 100%;

      .button__container {
        width: auto;
      }
    }
  }

  @media screen and (max-width: 425px) {
    h5 {
      line-height: 17px;
      span {
        max-width: 60%;
      }
    }
  }
}

.WrapperViewPDF {
  .testEmbed {
    .box {
      padding: scaleDPI(20);

      .Select,
      .Options,
      .Feedback {
        margin-top: scaleDPI(20) !important;
      }
    }

    .Options {
      .form-check {
        margin-bottom: 0;
        margin-left: 0;
        padding-left: 0;
      }

      .grid {
        padding: 0;

        li {
          border-bottom-width: 0;
          margin-bottom: 0;

          &:not(:last-child) {
            margin-bottom: scaleDPI(10);
          }
        }
      }
    }

    .Question__Info,
    .Question__Number,
    .DataLabel__Indicator {
      display: none;
    }

    h5 {
      font-size: scaleDPI(11);
    }

    .Question__Content p,
    .Initial__Scale,
    .Question__Option .Option__Label,
    .form-check-label,
    .question {
      font-size: scaleDPI(10);
    }

    // Pregunta escala
    .QuestionScale {
      .Option__Tooltip__Input {
        height: initial;
      }

      .form-check-input {
        margin-bottom: scaleDPI(10);
      }
    }

    .Feedback {
      margin-bottom: 0;
      padding: scaleDPI(15) scaleDPI(10);

      .State {
        display: none;
      }

      .Text {
        font-size: scaleDPI(9);
        padding: 0;

        span,
        p {
          font-size: scaleDPI(9);
        }
      }
    }
  }

  .QuestionAlternatives {
    &.checked input[type='radio'] {
      position: relative;

      &::before {
        background-color: $brownGrey;
        border-radius: 50%;
        content: '';
        height: scaleDPI(4.5);
        position: absolute;
        right: scaleDPI(1);
        top: scaleDPI(1);
        width: scaleDPI(4.5);
      }
    }

    &__label {
      & + div,
      span,
      p {
        line-height: scaleDPI(13);
      }
    }
  }

  // Select
  .SelectPDF {
    border: solid 1px $brownGreyTwo;
    border-radius: scaleDPI(1.5);
    font-size: scaleDPI(10);
    margin-top: scaleDPI(15);
    padding: 0.375rem 0.75rem;
    position: relative;

    &::after {
      border-bottom-width: 0;
      border-color: $brownGreyTwo;
      border-left-width: 0;
      border-right-width: 2px;
      border-style: solid;
      border-top-width: 2px;
      content: '';
      height: scaleDPI(5.5);
      position: absolute;
      right: scaleDPI(8);
      top: scaleDPI(7.5);
      transform: rotate(135deg);
      width: scaleDPI(5.5);
      z-index: 9;
    }
  }
}
