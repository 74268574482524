@import 'app/Components/UI/variables.scss';

/* styleForPDF */
.Article__audio {
  margin-bottom: 32px;
  width: 100%;

  audio {
    width: 100%;
  }

  &-error{
    display: flex;
    margin-top: 4px;
    .TinyAlert-Box{
      background-color: transparent;
      padding-left: 0;
      span{
        color: #cc0000;
      }
    }
    > button {
      font-size: 12px;
      padding-left: 0;
    }
  }

  .froala-updateAudio {
    width: 100% !important;
    audio {
      width: 100% !important;
    }
  }

  .froala-updateAudio-tool,
  .froala-updateAudio-titulo,
  .froala-updateAudio-descripcion {
    display: none;
  }
}
