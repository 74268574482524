@import 'app/Components/UI/variables.scss';

/* styleForPDF */
.PDFTimeline {
  > .label {
    display: flex;
    align-items: baseline;

    > h6 {
      margin: 0;
      color: #000000;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: scaleDPI(13);
      line-height: scaleDPI(14);
      border-right-style: solid;
      border-color: #e8e8e8;
      padding-right: 16px;
    }

    > p {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: scaleDPI(12);
      line-height: 9px;
      color: #808080;
      padding-left: 16px;
    }
  }

  > .content {
    padding-bottom: 16px;
    border-bottom: 1px dashed #c6c6c6;
    margin-bottom: scaleDPI(12);
  }
}
