@import 'app/Components/UI/variables.scss';

/* styleForPDF */
.QuestionAlternatives{
  list-style: none;
  padding-left: 0;

  &:not(:last-child) {
    margin-bottom: 6px;
  }

  label {
    align-items: flex-start;
    display: flex;
  }

  img {
    opacity: 1 !important;
    width: 100%;
  }

  .QuestionAlternatives__label{
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-right: 10px;
    min-width: 44px;
    position: relative;
    width: 44px;

    span {
      margin-right: 10px;
    }

    input {
      position: relative;
      margin: 0;
    }

    & > svg {
      margin-right: 0;
      margin-top: 2.5px;
    }
  }
}
