@import 'app/Components/UI/variables.scss';

/* styleForPDF */
.Article__image_box {
  align-items: center;
  display: flex;

  figure {
    margin: 0;
  }

  img {
    min-width: 250px;
    width: 100%;
    image-rendering: -webkit-optimize-contrast;
  }

  p {
    color: $completed;
    font-family: $fontLora;
    font-size: 20px;
    line-height: 34px;
    margin: 0;
  }

  &.hasParagraph {
    figure {
      margin-right: 50px;
    }
  }

  &.round,
  &.framed {
    img {
      height: 250px;
      object-fit: cover;
      width: 250px;
      image-rendering: -webkit-optimize-contrast;
    }
  }

  &.round {
    figure img {
      border-radius: 50%;
      image-rendering: -webkit-optimize-contrast;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    text-align: center;

    &.hasParagraph {
      figure {
        margin-right: 0;
        margin-bottom: 30px;
      }
    }

    p {
      font-size: 18px;
      line-height: 30px;
      color: $completed;
    }
  }
}

.WrapperViewPDF {
  .Article__image_box {
    flex-direction: row;
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;

    &.hasParagraph {
      figure {
        margin-right: scaleDPI(25);
      }
    }

    img {
      max-height: scaleDPI(124);
      max-width: scaleDPI(124);
      image-rendering: -webkit-optimize-contrast;
    }

    p {
      font-size: scaleDPI(11);
      line-height: normal;
    }
  }
}
