@import 'app/Components/UI/variables.scss';

/* styleForPDF */
.ContentArticle,
.ContentArticleChild {
  p > a,
  p > span[data-toggle='tooltip'], 
  li > a,
  li > span[data-toggle='tooltip']{
    background-image: linear-gradient(transparent 50%, $deepSkyBlue 10%);
    background-position-y: -0%;
    background-size: 2px;
    background-size: auto 190%;
    color: $deepSkyBlue !important;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
    transition: background 150ms ease;

    &:hover {
      background-position-y: 100%;
      color: white !important;
    }
  }

  p > sup,
  li > sup{
    &.footnote {
      > a {
        color: $deepSkyBlue;
        cursor: pointer;
        font-family: $fontRoboto;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 3px;
        background-image: none;

        &:hover {
          color: $blackTwo;
        }
      }
    }
  }

  abbr {
    color: $deepSkyBlue;
    text-decoration: none;
  }

  @media screen and (max-width: 768px) {
    a,
    span.qtip[data-toggle='tooltip'] {
      font-size: 16px !important;
    }
  }
}

.WrapperViewPDF .ContentArticle,
.WrapperViewPDF .ContentArticleChild{
  p > span[data-toggle='tooltip'], 
  li > span[data-toggle='tooltip'] {
    color: $deepSkyBlue;
    font-size: scaleDPI(10);
    padding-right: scaleDPI(11);
    position: relative;

    &:before {
      display: none;
    }

    &:after {
      content: url('../../../../assets/svg/group-4.svg');
      position: absolute;
      right: 0;
      top: scaleDPI(-1);
    }
  }
}
