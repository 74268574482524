@import 'app/Components/UI/variables.scss';

/* styleForPDF */
.SlideshowPDF {
  display: block;

  .slidePDF {
    align-items: flex-start;
    display: flex;
    column-gap: scaleDPI(5);
    color: $blackTwo;
    font-family: $fontRoboto;
    line-height: scaleDPI(13);
    margin-bottom: scaleDPI(10) !important;

    > div {
      width: 50%;
    }

    &:not(:last-child) {
      margin-bottom: scaleDPI(10);
    }

    @for $i from 0 through 15 {
      &.order-#{$i} {
        order: #{$i};
      }
    }

    figure {
      margin: 0;
      text-align: center;

      img {
        width: 100%;
      }
    }

    h5 {
      margin: 0 0 scaleDPI(8);
    }
  }

  &.type-vertical{
    .slidePDF {
      figure {
        img {
          width: initial;
          height: 100%;
          max-height: scaleDPI(155);
        }
      }
    }
  }
}
