@import 'app/Components/UI/variables.scss';

/* styleForPDF */
.legalText {
  color: $brownGreyTwo;
  font-size: 14px;
  line-height: 19px;
  margin: 32px 0;
  text-align: center;
  vertical-align: top;
}

.WrapperViewPDF {
  .legalText {
    font-size: scaleDPI(8);
    margin: scaleDPI(16) 0;
  }
}
