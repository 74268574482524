@import 'app/Components/UI/variables.scss';

/* styleForPDF */
.Article__quote {
  display: flex;
  justify-content: center;
  p {
    color: $completed;
    font-family: $fontLora;
    font-size: 20px;
    line-height: 33px;
    margin-bottom: 30px;
  }

  .Article__quote__container {
    max-width: 591px;
    align-items: flex-start;
    display: flex;
    &::before {
      content: url('../../../../../assets/svg/quote.svg');
      flex-shrink: 0;
      height: 23px;
      margin-top: 8px;
      margin-right: 10px;
      width: 30px;
    }
  }
  footer {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    padding: 0;
    p {
      margin: 0;
      padding: 0px;
      font-size: 12px;
      color: $blackTwo;
      display: block;
      &:nth-child(2) {
        color: $brownishGrey;
      }
      &::before {
        content: none;
      }
    }
    figure {
      margin: 0px;
      img {
        border-radius: 50%;
        height: 58px;
        width: 58px;
        object-fit: cover;
        margin-right: 10px;
      }
    }

    div {
      text-align: left;
      p {
        font-family: $fontRoboto;
        font-size: 12px;
        line-height: normal;
        &:first-child::before {
          content: none;
        }
        & + p {
          color: $brownishGrey;
          margin-top: 5px;
        }
      }
      a {
        color: $primary;
        font-size: 12px;
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }

  @media screen and (max-width: 768px) {
    p {
      font-family: $fontLora;
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 30px;
    }
    flexible {
      justify-content: flex-start;
      padding-left: 30px;
    }
  }
}

.WrapperViewPDF {
  .Article__quote {
    margin-left: scaleDPI(65);
    margin-right: scaleDPI(65);

    .Article__quote__container {
      &:before {
        margin-top: 0;
      }

      > p {
        font-family: $fontLora;
        font-size: scaleDPI(11);
        line-height: scaleDPI(13);
        margin-bottom: scaleDPI(10);
      }

      footer {
        justify-content: start;

        figure {
          img {
            display: none;
          }
        }

        div {
          p {
            font-family: $fontRoboto;
            font-size: scaleDPI(6);
            line-height: scaleDPI(8);
            margin-top: 0;
          }
        }
      }
    }
  }
}
