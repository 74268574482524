body>iframe {
  pointer-events: none;
}

/* css forzado para subir el ModalAlerta*/
div.chakra-modal__content-container {
  align-items: baseline;
}

.userway_buttons_wrapper,  mini-widget, nano-widget {
  display: none !important;
}
