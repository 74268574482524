.Question-Cloze{
  background-color: #e7f3f5;
  padding: 12px 20px;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";


  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input{
    background-clip: padding-box;
    background-color: #fff;
    border-radius: 0;
    border: 1px solid #8f959e;
    color: #495057;
    display: inline-block;
    font-size: .9375rem;
    font-weight: 400;
    height: calc(1.5em + 0.75rem + 2px);
    line-height: 1.5;
    max-width: 100%;
    min-width: 40.5px;
    padding: .375rem .75rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;    
  }

  input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
  }

  select {
    background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center / 8px 10px no-repeat;
    border-radius: 0;
    border: 1px solid #8f959e;
    color: #495057;
    display: inline-block;
    font-size: .9375rem;
    font-weight: 400;
    height: calc(1.5em + 0.75rem + 2px);
    line-height: 1.5;
    max-width: 100%;
    padding: .375rem 1.75rem .375rem .75rem;
    transition: box-shadow 0.15s ease-in-out;
    vertical-align: middle;
    width: auto;
  }

  input:disabled,
  select:disabled {
    background: #E9EEF1;
    cursor: not-allowed;
  }

  input:focus-visible,
  select:focus-visible {
    border-radius: 0;
    box-shadow: 0px 0px 0 3px #3b92eabd;
    outline: none;
  }

  h4{
    font-size: calc(0.950625rem + 0.6075vw);
    
    strong{
      font-weight: 400;
    }
  }

  p{
    margin-bottom: .5rem;
  }
  
  p, table{
    font-size: calc(0.90375rem + 0.045vw);
  }

  strong{
    font-weight: 700;
  }
}
